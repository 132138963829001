import { ErrorBoundary } from "@sentry/react";
import { ApplicationView } from "@mixitone/mvc";
import clsx from "clsx";

type PanelProps = {
  title: React.ReactNode;
  buttons?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  titleClassName?: string;
};

const ErrorState = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="text-center -translate-y-5">
        <p className="mb-4 text-lg font-medium">Something went wrong</p>
        <p className="text-sm text-gray-600">Please try again later</p>
      </div>
    </div>
  );
};

export const Panel = ApplicationView(
  ({ title, titleClassName, buttons, className, children }: PanelProps) => {
    return (
      <div className={clsx("panel group rounded-lg bg-white p-6 shadow-sm", className)}>
        <div className="flex flex-col h-full">
          <div className="flex items-center justify-between mb-4">
            <h2 className={clsx("text-lg font-medium", titleClassName)}>{title}</h2>
            <div>{buttons}</div>
          </div>
          <div className="grow">
            <ErrorBoundary fallback={<ErrorState />}>{children}</ErrorBoundary>
          </div>
        </div>
      </div>
    );
  },
);
