import { FloatingPortal } from "@floating-ui/react";
import cx from "clsx";
import useGlobalShortcut from "../hooks/useGlobalShortcut";
import useOutsideAlerter from "../hooks/useOutsideAlerter";
import ApplicationController, { ApplicationView, StartControllerScope } from "@mixitone/mvc";
import React, { useRef } from "react";
import { twMerge } from "tailwind-merge";

type Props = {
  open: boolean;
  onClose: () => void;
  title?: React.ReactNode;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  dropRef?: React.Ref<HTMLDivElement | null>;
  floatingProps?: any;
  portal?: boolean;
  animate?: boolean;
} & Pick<React.HTMLAttributes<HTMLDivElement>, "className" | "role">;

class DropdownController extends ApplicationController<
  { onClose: () => void; dropRef?: React.Ref<HTMLDivElement | null> },
  Props
> {
  override async initialize(props: Props) {
    this.state.onClose = props.onClose;
    this.state.dropRef = props.dropRef;
  }

  async actionClose() {
    this.state.onClose();
  }
}

const DropdownComponent: React.FC<Props> = ({
  children,
  title,
  open,
  style,
  floatingProps,
  portal,
  animate,
  className,
  role,
}) => {
  const controller = DropdownController.use();
  const { dropRef } = controller.state;
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useOutsideAlerter(
    dropdownRef,
    () => {
      if (!open) return;
      controller.actionClose();
    },
    { active: open },
  );

  useGlobalShortcut(
    "Escape",
    () => {
      if (!open) return;
      controller.actionClose();
    },
    { overrides: true, active: open },
  );

  const dropdownElement = (
    <div
      ref={(node) => {
        dropdownRef.current = node;
        if (dropRef) {
          if (typeof dropRef === "function") {
            dropRef(node);
          } else {
            // @ts-ignore
            dropRef.current = node;
          }
        }
      }}
      style={style}
      className={twMerge(
        cx(
          [
            "z-50",
            "absolute",
            "mt-2",
            "rounded-md",
            "",
            "border-gray-100",
            "shadow-lg",
            "shadow-gray-400",
            "bg-white",
            "ring-1",
            "ring-black",
            "ring-opacity-5",
            "focus:outline-none",
            "opacity-0",
            "text-sm",
          ],
          {
            "transition-opacity duration-100": animate,
            "opacity-100": open,
            "pointer-events-none": !open,
          },
        ),
        className,
      )}
      role={role || "menu"}
      aria-orientation="vertical"
      aria-labelledby="options-menu"
      aria-hidden={!open}
      {...floatingProps}
    >
      <div className="py-1" role="none">
        {title && (
          <div className="px-4 py-2 text-center text-gray-700 border-b whitespace-nowrap">{title}</div>
        )}
        {children}
      </div>
    </div>
  );

  if (portal === false) {
    return dropdownElement;
  }

  return <FloatingPortal>{dropdownElement}</FloatingPortal>;
};

const Dropdown = StartControllerScope(DropdownController, ApplicationView(DropdownComponent));

export { Dropdown, DropdownController };
