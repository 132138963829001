import Group from "@mixitone/models/Group";
import { ApplicationView } from "@mixitone/mvc";
import { GroupDot } from "./GroupDot";

export const GroupDotName = ApplicationView(({ group }: { group: Group }) => {
  return (
    <div className="flex items-center justify-start gap-2">
      <GroupDot group={group} size={16} />
      <span>{group.name}</span>
    </div>
  );
});
