import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

type RemoveUnderscoreFirstLetter<S extends string> = S extends `${infer FirstLetter}${infer U}`
  ? `${FirstLetter extends "_" ? U : `${FirstLetter}${U}`}`
  : S;
type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? "_" : ""}${RemoveUnderscoreFirstLetter<Lowercase<T>>}${CamelToSnakeCase<U>}`
  : S;
type KeysToSnakeCase<T extends object> = {
  [K in keyof T as CamelToSnakeCase<K & string>]: T[K];
};

export type Nullable<T> = T | null | undefined;
export type NonNullable<T> = T extends null | undefined ? never : T;

export function pick<T extends object, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
  const result: Partial<T> = {};
  for (const key of keys) {
    result[key] = obj[key];
  }
  return result as Pick<T, K>;
}
export function omit<T extends object, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> {
  const result: Partial<T> = { ...obj };
  for (const key of keys) {
    delete result[key];
  }
  return result as Omit<T, K>;
}
function toSnakeCase<T extends object>(obj: T) {
  return Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      [camelToSnake(key)]: (obj as any)[key],
    }),
    {},
  ) as KeysToSnakeCase<T>;
}
function camelToSnake(str: string): string {
  return str.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`);
}
export function pickSnake<T extends object, K extends keyof T>(obj: T, keys: K[]) {
  return toSnakeCase(pick(obj, keys));
}
export function keys<T extends object>(object: T): Array<keyof T> {
  return Object.keys(object) as Array<keyof T>;
}

export function entries<T extends object>(object: T): [keyof T, T[keyof T]][] {
  return Object.entries(object) as [keyof T, T[keyof T]][];
}

const isNilSymbol = Symbol("isNil");
export { isNilSymbol };

export function isNil(value: any): value is null | undefined {
  if (value && value[isNilSymbol]) return true;
  return value === null || value === undefined;
}

export function isPresent<T>(value: T | null | undefined): value is T {
  return !isNil(value);
}

export function isUndefined(value: any): value is undefined {
  return value === undefined;
}

export function isObjectLike(value: any): boolean {
  return typeof value === "object" && value !== null;
}

export function first<T>(array: T[]): T | undefined {
  return array[0];
}

export function sum(numbers: number[]): number {
  return numbers.reduce((total, num) => total + num, 0);
}

export function sample<T>(array: T[]): T | undefined {
  const index = Math.floor(Math.random() * array.length);
  return array[index];
}

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function mapValues<T extends object, R>(
  obj: T,
  callback: (value: T[keyof T], key: keyof T, obj: T) => R,
): { [K in keyof T]: R } {
  const result: Partial<{ [K in keyof T]: R }> = {};
  for (const key in obj) {
    result[key] = callback(obj[key], key, obj);
  }
  return result as { [K in keyof T]: R };
}

export function mapKeys<T extends object, K extends string>(
  obj: T,
  callback: (key: keyof T, value: T[keyof T], obj: T) => K,
): { [P in K]: T[keyof T] } {
  const result: Partial<{ [P in K]: T[keyof T] }> = {};

  for (const key in obj) {
    const newKey = callback(key, obj[key], obj);
    result[newKey] = obj[key];
  }

  return result as { [P in K]: T[keyof T] };
}

export function keyBy<T extends object>(array: T[], keyFn: (item: T) => any): Record<string, T> {
  const result: Record<string, T> = {} as any;
  for (const item of array) {
    result[String(keyFn(item))] = item;
  }
  return result;
}

export function indexBy<T extends object>(
  array: T[],
  keyOrKeyFn: keyof T | ((item: T) => any),
): Record<string, T[]> {
  const keyFn = typeof keyOrKeyFn === "function" ? keyOrKeyFn : (item: T) => item[keyOrKeyFn];

  return array.reduce(
    (result, item) => {
      const key = String(keyFn(item));
      if (!result[key]) result[key] = [];
      result[key].push(item);
      return result;
    },
    {} as Record<string, T[]>,
  );
}

export function removeMultipleIndices<T>(array: T[], indices: number[]): void {
  indices
    .toSorted((a, b) => b - a)
    .forEach((index) => {
      array.splice(index, 1);
    });
}

export type AsyncFunction = () => Promise<any>;

export async function parallel(...fns: Array<Promise<any> | AsyncFunction>): Promise<any[]> {
  // Wrap each function in a Promise, if it's not already a Promise
  const promises = fns.map((fn) => (fn instanceof Promise ? fn : fn()));

  // Wait for all Promises to resolve
  return await Promise.all(promises);
}

/**
 * This function sorts an array into random order
 */
export function shuffle<T>(array: T[]): T[] {
  const result = [...array];
  for (let i = 0; i < result.length; i++) {
    const randomIndex = Math.floor(Math.random() * result.length);
    const temp = result[i];
    result[i] = result[randomIndex];
    result[randomIndex] = temp;
  }
  return result;
}

/**
 * Swap two elements in an array
 */
export function arraySwap<T>(array: T[], index1: number, index2: number): void {
  if (index1 !== index2 && index1 >= 0 && index2 >= 0 && index1 < array.length && index2 < array.length) {
    const temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;
  }
}

export function t(key: string, ...args: string[]): string {
  return args
    .flatMap((arg) => arg.split(" "))
    .map((arg) => [key, arg].join(":"))
    .join(" ");
}

export interface ConvertOptions {
  includeHours?: boolean;
}

export function convertMillisecondsToReadableDuration(
  milliseconds: number,
  options?: ConvertOptions,
): string {
  // Calculate the number of hours, minutes, and seconds
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);

  // Pad the hours, minutes, and seconds with leading zeros if necessary
  const paddedHours = hours.toString().padStart(2, "0");
  const paddedMinutes = minutes.toString().padStart(2, "0");
  const paddedSeconds = seconds.toString().padStart(2, "0");

  // Return the formatted duration text
  const parts: string[] = [];
  if (options?.includeHours || hours > 0) parts.push(paddedHours);
  parts.push(paddedMinutes);
  parts.push(paddedSeconds);
  return parts.join(":");
}

export function timesToDuration(startedAt: Date, stoppedAt?: Date): number {
  if (startedAt) {
    if (stoppedAt) {
      return Math.abs(dayjs(stoppedAt).diff(dayjs(startedAt)));
    } else {
      return Math.abs(dayjs().diff(dayjs(startedAt)));
    }
  } else {
    return 0;
  }
}

const formatter = new Intl.DateTimeFormat(undefined, {
  timeStyle: "short",
});

export function formatTime(time: string | Date) {
  if (time instanceof Date) return formatter.format(time);
  const date = new Date("1/1/1 " + time);
  return formatter.format(date);
}

export function formatDate(date: Date | string | null | undefined) {
  return date ? dayjs(date).format("ll") : "never";
}

const numberFormatter = new Intl.NumberFormat();

export function formatNumber(number: number) {
  return numberFormatter.format(number);
}

export function isBrowser() {
  return typeof window !== "undefined";
}

/**
 * This function filters an array to unique items based on the return of the
 * given function for each item
 */
export function uniqBy<T>(array: T[], fn: (item: T) => any): T[] {
  const seen = new Set();
  return array.filter((item) => {
    const key = fn(item);
    return !seen.has(key) && seen.add(key);
  });
}

export type PropsWithClassName<P = unknown> = P & { className?: string | undefined };

export function compareDateStringOrNull(a: string | null | undefined, b: string | null | undefined): number {
  if (a == null && b == null) return 0;
  if (a == null) return 1;
  if (b == null) return -1;

  return dayjs(b).startOf("day").diff(dayjs(a).startOf("day"));
}

export function formatRelativeTime(pastDate: dayjs.ConfigType) {
  const now = dayjs();
  const past = dayjs(pastDate);
  const diffDays = now.diff(past, "day");

  if (now.isSame(past, "day")) {
    return "today";
  } else if (diffDays <= 7) {
    return `last ${past.format("dddd")}`; // Returns the full name of the day of the week
  } else if (diffDays <= 365) {
    return past.fromNow(); // This will return something like "6 months ago"
  } else {
    return "more than a year ago";
  }
}

/**
 * Debounce a function by the given number of milliseconds
 */
export function debounce<T extends (...args: any[]) => any>(fn: T, ms: number): T {
  let timeout: NodeJS.Timeout | undefined;

  return function (...args: any[]) {
    clearTimeout(timeout!);
    timeout = setTimeout(() => fn(...args), ms);
  } as any;
}

export function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function tryJsonParse<T>(json: string): T | null {
  try {
    return JSON.parse(json);
  } catch {
    return null;
  }
}

export function wwwUrl(path: string) {
  return `${import.meta.env.VITE_WWW}/${path}`;
}
